<template>
<div class="basic">
    <v-snackbar v-model="snackbar" :timeout="6000" color="error">
      {{ errmsg }}
    </v-snackbar>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-row class="lighten-5 align-center justify-center">
              <v-card
                :key="1"
                class="card1 ma-2 pa-3"
                elevation=0
                flat
                width=325
                color="transparent">
                <v-row dense>
                  <v-col cols="12" align="center">
                    <v-img :width="80" src="https://s3.ap-northeast-2.amazonaws.com/images.wintersleeping/main_pic.png" class="ma-0 pa-0" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="이메일"
                      prepend-icon="email"
                      required
                      class="ma-0 pa-0"
                      background-color="transparent" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field
                      v-model="password"
                      :rules="passRules"
                      :type="'password'"
                      label="패스워드"
                      prepend-icon="lock"
                      required
                      class="ma-0 pa-0"
                      background-color="transparent" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" >
                    <v-text-field
                      v-model="password_confirm"
                      :rules="passconfirmRules()"
                      :type="'password'"
                      label="패스워드 확인"
                      prepend-icon="lock"
                      required
                      class="ma-0 pa-0"
                      background-color="transparent" />
                  </v-col>
                </v-row>
                <v-row dense class="subtitle-2">
                  이름(별명)
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="name"
                      @change="check_name"
                      label="이름(별명)"
                      required
                      :error-messages="err_name"
                      :hint="hint_name"
                      background-color="transparent" />
                  </v-col>
                  <v-col>
                    <v-radio-group v-model="sex" row>
                      <v-radio label="남" value="M"></v-radio>
                      <v-radio label="여" value="F"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row dense class="subtitle-2">
                  약관동의
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-card outlined>
                      <v-card-text class="pa-2">
                        <v-row dense>
                          <v-col cols="2">
                            <v-checkbox v-model="agree_all" />
                          </v-col>
                          <v-col cols="6">
                            전체동의
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider />
                      <v-card-text class="pa-2">
                        <v-row dense>
                          <v-col cols="2">
                            <v-checkbox v-model="contract" />
                          </v-col>
                          <v-col cols="8">
                            <a href="/user/terms" target=blank_>이용 약관</a>
                          </v-col>
                          <v-col cols="2" class="caption">
                            (필수)
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text class="pa-2">
                        <v-row dense>
                          <v-col cols="2">
                            <v-checkbox v-model="handle_private" />
                          </v-col>
                          <v-col cols="8">
                            <a href="/user/private" target=blank_>개인정보취급방침</a>
                          </v-col>
                          <v-col cols="2" class="caption">
                            (필수)
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text class="pa-2">
                        <v-row dense>
                          <v-col cols="2">
                            <v-checkbox v-model="event_sms" />
                          </v-col>
                          <v-col cols="8">
                            이벤트, 프로모션 알림 메일 및 SMS 수신
                          </v-col>
                          <v-col cols="2" class="caption">
                            (선택)
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn
                      color="#FBDE44FF"
                      large
                      class="elevation-0"
                      @click="join"
                      :disabled="validation()"
                      block
                    >
                    회원가입
                    </v-btn>
                  </v-col>
                </v-row>
                <SNS />
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import SNS from './SNS';

export default {
  components: {
    SNS,
  },
  data: () => {
    return {
      agree_all: false,
      contract: false,
      handle_private: false,
      event_sms: false,
      snackbar: false,
      errmsg: "",
      valid: false,
      valid_name: false,
      name: "",
      err_name: "",
      hint_name: "",
      email: "",
      emailRules: [
        v => !!v || '이메일을 입력해야 합니다.',
        v => /.+@.+/.test(v) || '이메일 형식이 아닙니다.',
      ],
      password: "",
      passRules: [
        v => v.length >= 8 || '최소 8글자 이상을 입력하세요.',
      ],
      password_confirm: "",
      sex: 'M',
    }
  },
  watch: {
    agree_all : async function(v, ov) {
      if ( v == true ) {
        this.contract = true;
        this.handle_private = true;
        this.event_sms = true;
      } else {
        this.contract = false;
        this.handle_private = false;
        this.event_sms = false;
      }
    }

  },
  methods: {
    check_name: async function(v) {
      const is_username = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/name', {
        params: { name: v }
      });

      if ( is_username.data > 0 ) {
        this.err_name = "이미 같은 이름(별명)이 사용중입니다.";
        this.valid_name = false;
      } else {
        this.err_name = "";
        this.hint_name = "사용할 수 있습니다.";
        this.valid_name = true;
      }

    },
    validation: function() {
      if ( this.contract == true
        && this.handle_private == true
        && this.valid_name == true
        && this.valid == true ) {
        return false;
      } else
        return true
    },
    passconfirmRules: function() {
      return [
        v => v.length >= 8 || '최소 8글자 이상을 입력하세요.',
        v => {
          if ( v == this.password )
            return true;
          else
            return '입력한 비밀번호가 서로 다릅니다.';
        }
      ]
    },
    join: async function(ev) {
      ev.preventDefault();

      if (this.$refs.form.validate()) {

        try {

          const join_reault = await firebase.auth().createUserWithEmailAndPassword(
            this.email,
            this.password);

          if (join_reault.operationType=="signIn" && join_reault.additionalUserInfo.isNewUser==true) {
            // Send Id token to backend
            const id_token = await firebase.auth().currentUser.getIdToken(true);

            await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user', {
              name: this.name,
              uid: join_reault.user.uid,
              email: this.email,
              sex: this.sex,
              agreement_use: this.contract,
              agreement_private: this.handle_private,
              agreement_event: this.event_sms,
            }, {
              headers: {'id_token': id_token},
            });

          }

          this.$router.push({ path:"/main" });
        } catch (e) {
          if ( e.code == "auth/email-already-in-use" ) {
            this.snackbar = false;
            this.snackbar = true;
            this.errmsg = "이미 등록된 이메일입니다.";
          }
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
